import React from "react";
import {
    Modal,
    Button,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    useDisclosure,
    ModalFooter,
    ModalCloseButton,
    Flex,
} from "@chakra-ui/react";

const ConfirmationDialog = ({ children, modalTitle = "Please confirm", modalContent = "Are you sure?", onConfirm }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()

    const handleConfirm = () => {
        onConfirm();
        onClose();
    }

    return (
        <>
            <span onClick={onOpen}>
                {children}
            </span>

            <Modal onClose={onClose} isOpen={isOpen} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{modalTitle}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {modalContent}
                    </ModalBody>
                    <ModalFooter>
                        <Flex flexDirection={"row"} gap={2}>
                            <Button onClick={onClose}>Cancel</Button>
                            <Button onClick={handleConfirm} color={"red.400"}>Delete</Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ConfirmationDialog;