import { Button, Flex, Link, Menu, MenuButton, MenuItem, MenuList, Spacer, Spinner, Stack, Text } from "@chakra-ui/react";
import { useContext } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { ApiContext } from "../../ApiContext";
import useUserContext from "../../queries/useUserContext";
import BurgerIcon from "../icons/BurgerIcon";
import ChartIcon from "../icons/ChartIcon";
import FeedIcon from "../icons/FeedIcon";
import TooltipAvatar from "./TooltipAvatar";

const FragmentSelector = ({ isMobile, onOpen }) => {
    const { journalId } = useParams();
    const { context, isLoading } = useUserContext();
    const { onLogout } = useContext(ApiContext);

    const mobileSidebar = {
        direction: "row",
        justifyContent: "space-around",
        alignItems: "flex-start",
        width: "100vw",
        height: '3.5em',
        bottom: 0,
        left: 0,
        padding: "0.1rem",
    }

    const desktopSidebar = {
        direction: "column",
        justifyContent: "space-between",
        alignItems: "center",
        width: '7rem',
        height: "100vh",
        top: 0,
        right: 0,
    }

    const sidebarSettings = isMobile ? mobileSidebar : desktopSidebar;
    const buttonSize = isMobile ? "3rem" : "6rem";
    const iconSize = isMobile ? "2rem" : "3rem";

    return (
        <Flex
            {...sidebarSettings}
            as={"aside"}
            position={'fixed'}
            bg="gray.900"
        >
            {isMobile && (
                <Button
                    width={buttonSize}
                    height={buttonSize}
                    aria-label="Toggle Menu"
                    onClick={onOpen}
                    variant={"ghost"}
                >
                    <BurgerIcon width={iconSize} height={iconSize} />
                </Button>
            )}
            <Link as={RouterLink} to={`/journal/${journalId}/`} position={"sticky"} top={"1rem"}>
                <Button width={buttonSize} height={buttonSize} variant={"ghost"}>
                    <Stack spacing={3}>
                        <FeedIcon width={iconSize} height={iconSize} />
                        {!isMobile && <Text>Feed</Text>}
                    </Stack>
                </Button>
            </Link>
            <Link as={RouterLink} to={`/journal/${journalId}/charts/`} position={"sticky"} top={"8rem"}>
                <Button width={buttonSize} height={buttonSize} variant={"ghost"}>
                    <Stack spacing={3}>
                        <ChartIcon width={iconSize} height={iconSize} />
                        {!isMobile && <Text>Charts</Text>}
                    </Stack>
                </Button>
            </Link>
            {!isMobile && <Spacer />}
            <Menu>
                <MenuButton as={Button} variant={"ghost"} marginBottom={"1.5rem"} width={buttonSize} height={buttonSize}>
                    {!isLoading
                        ? <TooltipAvatar size="sm" name={context?.username} />
                        : <Spinner />}
                </MenuButton>
                <MenuList>
                    <MenuItem disabled>Settings</MenuItem>
                    <MenuItem onClick={onLogout}>Logout</MenuItem>
                </MenuList>
            </Menu>
        </Flex>
    );
};

export default FragmentSelector;