import { useContext } from "react";
import {
    useQuery,
} from '@tanstack/react-query';
import { ApiContext } from "../ApiContext";

const useJournalMetadata = ({ journalId }) => {
    const { api } = useContext(ApiContext);
    const { data, error, isLoading } = useQuery({
        queryKey: [`/journals/${journalId}/metadata/`],
        queryFn: async () => api.get(`/journals/${journalId}/metadata/`),
    });
    return { metadata: data?.data || {}, error, isLoading };
}

export default useJournalMetadata;