import {
  FormLabel,
  Input,
  Container,
  Button,
  Heading,
  Card,
  CardBody,
  CardFooter,
  Center,
  Flex,
  Spacer,
  useToast,
} from "@chakra-ui/react";
import React, { useState, useContext } from "react";
import { ApiContext } from "../ApiContext";
import usePageTitle from "../hooks/usePageTitle";
import LogoIcon from "./icons/LogoIcon";

const LoginForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { onLogin } = useContext(ApiContext);
  const toast = useToast();
  usePageTitle(["Login"]);

  const handleLoginError = (error) => {
    toast({
        title: "There was a problem during the login attempt. Try again, or validate your account.",
        status: 'error',
        duration: 6000,
        isClosable: true,
        position: "top-right",
    });
}

  const handleLogin = async (e) => {
    e.preventDefault();
    await onLogin({ username, password }).catch(handleLoginError);
  };

  return (
    <Flex justifyContent={"center"} alignItems={"center"} height={"90vh"}>
      <Container>
        <Center marginBottom={"2rem"}>
          <LogoIcon width={"auto"} height={"2rem"} fillColor={"#fff"} />
          <Spacer />
          <Heading as="h3" size="md">
            Login to your account
          </Heading>
        </Center>
        <form onSubmit={handleLogin}>
          <Card>
            <CardBody>
              <FormLabel>Username</FormLabel>
              <Input
                pr="4.5rem"
                type={"text"}
                onChange={(e) => setUsername(e.target.value)}
                autoComplete="username"
                placeholder="Enter username"
              />
              <FormLabel marginTop={5}>Password</FormLabel>
              <Input
                pr="4.5rem"
                type={"password"}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="current-password"
                placeholder="Enter password"
              />
            </CardBody>

            <CardFooter>
              <Button type="submit">Login</Button>
            </CardFooter>
          </Card>
        </form>
      </Container>
    </Flex>
  );
}

export default LoginForm;
