import { useContext } from "react";
import {
    useMutation,
    useQueryClient,
} from '@tanstack/react-query';
import { ApiContext } from "../ApiContext";

const useCreateJournalChart = ({ journalId, author }) => {
    const { api } = useContext(ApiContext);
    const queryClient = useQueryClient();

    const mutation = useMutation({
        queryKey: ["chart"],
        mutationFn: async ({ type, operation_type, label_column, value_columns }) => api.post("/charts/", {
            type,
            operation_type,
            label_column,
            value_columns,
            journal: journalId,
            author,
        }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [`/charts/?journal_id=${journalId}`] });
        },
    });
    return mutation;
}

export default useCreateJournalChart;