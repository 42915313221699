import { Avatar, Tooltip } from "@chakra-ui/react";

const TooltipAvatar = (props) => {
    return (
        <Tooltip hasArrow label={props.name}>
            <Avatar {...props} />
        </Tooltip>
    );
}

export default TooltipAvatar;