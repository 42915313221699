import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Text,
    Badge,
    Flex,
} from '@chakra-ui/react'

const MetadataTable = ({ collection }) => {
    return (
        Object.keys(collection).length > 0 &&
        <Accordion allowMultiple marginTop="2rem">
            <AccordionItem>
                <AccordionButton>
                    <Flex alignItems="flex-end" gap="0.5rem" as="span" flex='1' textAlign='left' size="sm">
                        <Text fontSize="sm">Properties</Text>
                        <Badge>
                            {Object.keys(collection).length}
                        </Badge>
                    </Flex>
                    <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                    <TableContainer>
                        <Table size='sm'>
                            <Thead>
                                <Tr>
                                    <Th>Property</Th>
                                    <Th>Value</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {Object.keys(collection).map((key, i) => (
                                    <Tr>
                                        <Td>{key}</Td>
                                        <Td>{collection[key]}</Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    );
}

export default MetadataTable;