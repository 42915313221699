import { Icon } from '@chakra-ui/react';

const FeedIcon = ({ fillColor = "#ffffff", strokeWidth = "2", ...rest }) => (
    <Icon viewBox='0 0 24 24' fill="none" stroke={fillColor} {...rest}>
        <path d="M3 4V18C3 19.1046 3.89543 20 5 20H17H19C20.1046 20 21 19.1046 21 18V8H17" stroke={fillColor} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"></path>
        <path d="M3 4H17V18C17 19.1046 17.8954 20 19 20V20" stroke={fillColor} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"></path> <path d="M13 8L7 8" stroke={fillColor} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"></path>
        <path d="M13 12L9 12" stroke={fillColor} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"></path>
    </Icon>
);

export default FeedIcon;
