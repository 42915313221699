import { useNavigate } from "react-router-dom";
import usePageTitle from "../hooks/usePageTitle";
import useJournals from "../queries/useJournals";
import FullScreenSpinner from "./controls/FullScreenSpinner";
import SetupWizard from "./SetupWizard";

function HomePage() {
  const navigate = useNavigate();
  const { journals, isLoading } = useJournals();
  usePageTitle(["Loading..."]);

  if (!isLoading) {
    if (journals.count > 0) {
      return navigate(`/journal/${journals.results[0].id}/`);
    }
    return <SetupWizard />;
  }
  return <FullScreenSpinner />;
}

export default HomePage;
