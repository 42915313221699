import React, { useEffect } from "react";
import {
    Button,
    Flex,
    Spinner,
    Spacer,
    Box,
} from "@chakra-ui/react";
import EmptyPlaceholder from "./EmptyPlaceholder";
import RichTextEditorQuill from "../controls/RichTextEditorQuill";
import DailyLogCard from "./DailyLogCard";
import useDailyLogs from "../../queries/useDailyLogs";
import { useInView } from "react-intersection-observer";
import { useParams } from "react-router-dom";
import BackToTop from "../controls/BackToTop";

const JournalFeed = () => {
    const { journalId } = useParams();
    const initialValue = "";
    const { dailyLogs, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useDailyLogs({ journalId });
    const { ref, inView } = useInView();

    useEffect(() => {
        if (inView) {
            fetchNextPage()
        }
    }, [inView, fetchNextPage])

    const resultsCount = dailyLogs?.pages?.map((page) => page.data.count).find(() => true);

    // const getDailyLogs = () => dailyLogs?.pages?.flatMap(
    //     (page) => page.data.results.map((dailyLog) => dailyLog)
    // ) || [];

    return (
        <Flex flexDirection={"column"} justifyContent={"space-between"} gap={5}>
            <Spacer />
            <RichTextEditorQuill initialContent={initialValue} readOnly={false} alwaysEditable />
            <Spacer />
            {dailyLogs?.pages?.length > 0 &&
                dailyLogs?.pages?.map(
                    (page) => page.data.results.map((dailyLog) =>
                        <DailyLogCard key={dailyLog.id} document={dailyLog} />
                    )
                )
            }

            <Flex flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                <Button
                    ref={ref}
                    variant={"ghost"}
                    onClick={() => fetchNextPage()}
                    disabled={!hasNextPage || isFetchingNextPage}
                    visibility={isFetchingNextPage || hasNextPage ? "auto" : "hidden"}  // visible only while fetching
                >
                    {isFetchingNextPage
                        ? (
                            <Spinner />
                        )
                        : hasNextPage
                            ? 'Load more'
                            : 'Nothing more to load'}
                </Button>
                {resultsCount === undefined && isFetching && !isFetchingNextPage ? <Spinner /> : null}
                {!isFetching && <Flex direction="column" justifyContent={"space-between"} gap={"0.5rem"} margin={"3rem"}>
                    <Box>
                        {resultsCount
                            ? `${resultsCount} entries`
                            : <EmptyPlaceholder message={"Start writing your amazing content"} />
                        }
                    </Box>
                    <Spacer />
                    <Box>
                        <BackToTop />
                    </Box>
                </Flex>}
            </Flex>
        </Flex>
        // <Flex width={"60ch"}>
        //     <RecyclerFeed getData={getDailyLogs} />
        // </Flex>
    );
}

export default JournalFeed;