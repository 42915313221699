import React from 'react';
import {
    Box,
    Button,
    Flex,
    useDisclosure,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerOverlay,
    DrawerHeader,
    Spacer,
} from '@chakra-ui/react';

import useResponsive from '../../hooks/useResponsive';
import FragmentSelector from '../controls/FragmentSelector';


const ResponsiveLayout = ({ sidebarContent, mainContent, navbar = null, footer = null }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isMobile } = useResponsive();

    return (
        <Flex flexDirection={'column'} minHeight={"100vh"}>
            <Flex flexDirection={'row'} minHeight={"100vh"}>
                {isMobile
                    ? (
                        <Drawer placement={"right"} onClose={onClose} isOpen={isOpen}>
                            <DrawerOverlay />
                            <DrawerContent bg={"gray.900"}>
                                <DrawerHeader borderBottomWidth='1px'><Button onClick={onClose}>Close</Button></DrawerHeader>
                                <DrawerBody>
                                    {sidebarContent}
                                </DrawerBody>
                            </DrawerContent>
                        </Drawer>
                    )
                    : (
                        <Box
                            as={"aside"}
                            width={'20rem'}
                            position={'fixed'}
                            bg="gray.900"
                            zIndex={'99'}
                        >
                            {sidebarContent}
                        </Box>
                    )
                }

                <Flex
                    flexDirection={"column"}
                    flex="1"
                    w={"calc(100% - 27rem)"}
                    marginLeft={isMobile ? 0 : "20rem"}
                    marginRight={isMobile ? 0 : "7rem"}
                >
                    <Flex p={4} justifyContent={"space-between"} alignItems={"center"}>
                        {navbar}
                        <Spacer />
                    </Flex>

                    {mainContent}
                    <Spacer />
                    {footer}
                </Flex>

                <FragmentSelector isMobile={isMobile} onOpen={onOpen} />
            </Flex>
        </Flex>
    );
};

export default ResponsiveLayout;
