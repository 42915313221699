import React, {
  createContext,
  useEffect,
} from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { extractJwtTokenParts, isJwtTokenValid, removeJwtToken, setJwtToken, getJwtToken } from "./utils/jwtTokenUtils";

const ApiContext = createContext();

const ApiProvider = ({ children }) => {

  const location = useLocation();
  const navigate = useNavigate();

  // const setPageTitle = ({ pathname }) => document.title = pathname; // TODO

  const onLogin = async ({ username, password }) => {
    const response = await api.post("/token/", { username, password });
    const { access } = response.data;
    setJwtToken({ value: access });

    if (isJwtTokenValid()) {
      navigate("/");
    }
  }

  const onLogout = () => {
    removeJwtToken();
    navigate("/login");
  }

  useEffect(() => {
    // setPageTitle({ pathname: location.pathname })

    if (!isJwtTokenValid() && location.pathname !== "/login") {
      navigate("/login");
    }

    if (isJwtTokenValid() && location.pathname === "/login") {
      navigate("/");
    }
  }, [location, navigate]);

  const api = axios.create({ baseURL: "https://api.somuo.com/v1/" });

  // Request interceptor for API calls
  api.interceptors.request.use(
    async config => {
      config.headers = {
        'Authorization': `Bearer ${getJwtToken()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
      }
      return config;
    },
    error => {
      Promise.reject(error)
    });

  // redirect to login if the response is Forbidden or Unauthorized
  api.interceptors.response.use((response) => {
    return response
  }, async function (error) {
    const originalRequest = error.config
    console.error("Interceptor error: " + JSON.stringify(error));
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      window.location.href = "/login";

      // const access_token = await refreshAccessToken();
      // axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
      return api(originalRequest);
    }
    return Promise.reject(error);
  });

  const { userId } = extractJwtTokenParts();

  return (
    <ApiContext.Provider value={{ api, loggedUserId: userId, onLogin, onLogout }}>
      {children}
    </ApiContext.Provider>
  );
};

export { ApiContext, ApiProvider };
