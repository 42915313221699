import { useContext } from "react";
import {
    useInfiniteQuery,
} from '@tanstack/react-query';
import { ApiContext } from "../ApiContext";

const useDailyLogs = ({ journalId }) => {
    const { api } = useContext(ApiContext);
    const firstPageUrl = `/dailylogs/?journal_id=${journalId}&page=1`;
    const {
        status,
        data,
        error,
        isFetching,
        isFetchingNextPage,
        isFetchingPreviousPage,
        fetchNextPage,
        fetchPreviousPage,
        hasNextPage,
        hasPreviousPage,
    } = useInfiniteQuery({
        queryKey: ["dailylogs", journalId],
        queryFn: async ({ pageParam = firstPageUrl }) => api.get(pageParam),
        getNextPageParam: (lastPage) => lastPage?.data?.next ?? undefined,
        getPreviousPageParam: (firstPage) => firstPage?.data?.previous ?? undefined,
        staleTime: 20,
    });
    return {
        dailyLogs: data || [],
        error,
        isFetching,
        fetchNextPage,
        fetchPreviousPage,
        hasNextPage,
        hasPreviousPage,
        isFetchingNextPage,
        isFetchingPreviousPage,
        status,
    };
}

export default useDailyLogs;