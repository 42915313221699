import {
    Box,
    Flex,
    AvatarGroup,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverArrow,
    PopoverCloseButton,
} from "@chakra-ui/react";
import TooltipAvatar from "./TooltipAvatar";

const ContributorAvatars = ({ journal, users }) => {
    return (
        users.length > 0 && (
            <Popover>
                <PopoverTrigger>
                    <Box tabIndex='0' role='button' aria-label='Contributors'>
                        <AvatarGroup size='sm' max={2} spacing={"-0.4rem"} fontSize={"0.65rem"}>
                            <TooltipAvatar key={`${journal.id}-${journal.author_username}`} name={journal.author_username} />
                            {users.map((contributor) =>
                                <TooltipAvatar key={`${journal.id}-${contributor}`} name={contributor} />
                            )}
                        </AvatarGroup>
                    </Box>
                </PopoverTrigger>
                <PopoverContent>
                    <PopoverHeader fontWeight='semibold'>Contributors</PopoverHeader>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody>
                        <Flex flexDirection={"row"}>
                            <TooltipAvatar key={`${journal.id}-${journal.author_username}-tooltip`} name={journal.author_username} size="sm" m={1} />

                            {users.map((contributor) =>
                                <TooltipAvatar key={`${journal.id}-${contributor}-tooltip`} name={contributor} size="sm" m={1} />
                            )}
                        </Flex>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        )
    );
};

export default ContributorAvatars;