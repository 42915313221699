import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ApiProvider } from "./ApiContext";
import LoginForm from "./components/LoginForm";
import Dashboard from "./components/Dashboard";
import HomePage from "./components/HomePage";
import JournalFeed from "./components/layout/JournalFeed";
import JournalCharts from "./components/layout/JournalCharts";

function App() {
  return (
    <Router>
      <ApiProvider>
        <Routes>
          <Route path="/login" element={<LoginForm />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/journal" element={<HomePage />} />
          <Route path="/journal/:journalId" element={<Dashboard />}>
            <Route index element={<JournalFeed/>} />
            <Route path="charts" element={<JournalCharts />} />
          </Route>
        </Routes>
      </ApiProvider>
    </Router>
  );
}

export default App;
