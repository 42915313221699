import { Icon } from '@chakra-ui/react';

const OptionMenuIcon = (props) => (
    <Icon viewBox='0 0 16 16' {...props}>
        <path
            fill='currentColor'
            d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
    </Icon>
);

export default OptionMenuIcon;