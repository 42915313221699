import React, { useState } from "react";
import {
    Button,
    Checkbox,
    CheckboxGroup,
    Flex,
    FormLabel,
    Grid,
    GridItem,
    Select,
    useDisclosure
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import useJournalCharts from "../../queries/useJournalCharts";
import useJournalMetadata from "../../queries/useJournalMetadata";
import SomuoBarChart from "../charts/SomuoBarChart";
import SomuoLineChart from "../charts/SomuoLineChart";
import SaveDiscardButtons from "../controls/SaveDiscardButtons";
import ChartCard from "./ChartCard";
import CreateChartModal from "../controls/CreateChartModal";
import useCreateJournalChart from "../../queries/useCreateJournalChart";
import { extractJwtTokenParts } from "../../utils/jwtTokenUtils";

const JournalCharts = () => {
    const { journalId } = useParams();
    const { userId } = extractJwtTokenParts();
    const { metadata, isLoading: isLoadingMetadata } = useJournalMetadata({ journalId });
    const { charts, isLoading: isLoadingCharts } = useJournalCharts({ journalId });

    const [chartType, setChartType] = useState()
    const [operationType, setOperationType] = useState()
    const [groupBy, setGroupBy] = useState()
    const [valueColumns, setValueColumns] = useState([])
    const createJournalChart = useCreateJournalChart({ journalId, author: userId });
    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleSave = () => {
        console.log(chartType);
        console.log(operationType);
        console.log(groupBy);
        console.log(valueColumns);
        createJournalChart.mutate({
            type: chartType,
            operation_type: operationType,
            label_column: groupBy,
            value_columns: valueColumns,
        });
        handleCancel();
    };

    const handleCancel = () => {
        setChartType();
        setOperationType();
        setGroupBy();
        setValueColumns([]);
        onClose();
    };


    return (
        <Flex flexDirection={"column"} justifyContent={"space-between"} gap={5} marginTop={"1rem"}>
            <Button onClick={onOpen}>Create New</Button>
            <CreateChartModal isOpen={isOpen} onClose={onClose}>
                <Flex flexDirection={"column"} justifyContent={"space-between"} gap={5} marginTop={"1rem"} marginBottom={"1rem"}>
                    <Flex flexDirection={"column"} justifyContent={"space-between"} gap={5}>
                        <FormLabel>Create a</FormLabel>
                        <Select placeholder='--' onChange={(e) => setChartType(e.target.value)}>
                            <option value='line'>Line Chart</option>
                            <option value='bar'>Bar Chart</option>
                        </Select>

                        <FormLabel>Grouping by</FormLabel>
                        <Select placeholder='--' onChange={(e) => setGroupBy(e.target.value)}>
                            {metadata?.columns?.map((column) =>
                                <option value={column}>{column}</option>
                            )}
                        </Select>

                        <FormLabel>Computing the</FormLabel>
                        <Select placeholder='--' onChange={(e) => setOperationType(e.target.value)}>
                            <option value='sum'>Sum</option>
                            <option value='avg'>Average</option>
                            <option value='count_distinct'>Count Distinct</option>
                        </Select>

                        <FormLabel>Over the following values</FormLabel>
                        <CheckboxGroup colorScheme='teal'>
                            <Grid templateColumns='repeat(5, 1fr)' gap={4}>
                                {metadata?.columns?.map((column) =>
                                    <GridItem key={`value-col-${column}`} colSpan={1} h='10'>
                                        <Checkbox
                                            value={column}
                                            onChange={(e) => {
                                                const valueColumn = e.target.value;
                                                const isChecked = e.target.checked;
                                                if (isChecked) {
                                                    setValueColumns([...valueColumns, valueColumn]);
                                                }
                                                else {
                                                    setValueColumns(valueColumns.filter((x) => x !== valueColumn));
                                                }
                                            }}>
                                            {column}
                                        </Checkbox>
                                    </GridItem>
                                )}
                            </Grid>
                        </CheckboxGroup>

                        <SaveDiscardButtons onCancel={handleCancel} onSave={handleSave} />
                    </Flex>
                </Flex>
            </CreateChartModal>
            {!isLoadingMetadata && !isLoadingCharts && charts
                ? (
                    charts.results.map((chart) => (
                        <ChartCard key={chart.id} document={chart}>
                            {chart.type === "line" && (
                                <SomuoLineChart
                                    records={chart.records}
                                    labelColumn={chart.label_column}
                                    valueColumns={chart.value_columns} />
                            )}
                            {chart.type === "bar" && (
                                <SomuoBarChart
                                    records={chart.records}
                                    labelColumn={chart.label_column}
                                    valueColumns={chart.value_columns} />
                            )}
                        </ChartCard>
                    ))
                )
                : "loading..."}
        </Flex>
    );
};

export default JournalCharts;