import { useContext } from "react";
import {
    useQuery,
} from '@tanstack/react-query';
import { ApiContext } from "../ApiContext";

const useJournals = () => {
    const { api } = useContext(ApiContext);
    const { data, error, isLoading } = useQuery({
        queryKey: ["journals"],
        queryFn: async () => api.get("/journals/"),
        staleTime: 20,
    });
    return { journals: data?.data || [], error, isLoading };
}

export default useJournals;