import { useContext } from "react";
import {
    useQuery,
} from '@tanstack/react-query';
import { ApiContext } from "../ApiContext";

const useJournalCharts = ({ journalId }) => {
    const { api } = useContext(ApiContext);
    const { data, error, isLoading } = useQuery({
        queryKey: [`/charts/?journal_id=${journalId}`],
        queryFn: async () => api.get(`/charts/?journal_id=${journalId}`),
    });
    return { charts: data?.data || {}, error, isLoading };
}

export default useJournalCharts;