import { Button } from "@chakra-ui/react";

const BackToTop = () => {

    const backToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    return (
        <Button variant={"link"} onClick={backToTop}>Back to top</Button>
    );
}

export default BackToTop;