import { useContext } from "react";
import {
    useQuery,
} from '@tanstack/react-query';
import { ApiContext } from "../ApiContext";

const useUserContext = () => {
    const { api } = useContext(ApiContext);
    const { data, error, isLoading } = useQuery({
        queryKey: ["user-context"],
        queryFn: async () => api.get(`/users/context/`),
        staleTime: 60,
    });
    return { context: data?.data || [], error, isLoading };
}

export default useUserContext;