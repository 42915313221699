import { useContext } from "react";
import {
    useMutation,
    useQueryClient,
} from '@tanstack/react-query';
import { ApiContext } from "../ApiContext";

const useDeleteDailyLog = ({ journalId, dailyLogId }) => {
    const { api } = useContext(ApiContext);
    const queryClient = useQueryClient();

    const mutation = useMutation({
        queryKey: ["journals"],
        mutationFn: async () => api.delete(`/dailylogs/${dailyLogId}/`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['dailylogs', journalId] });
        },
    });
    return mutation;
}

export default useDeleteDailyLog;