import { useEffect, useState } from "react";

const useResponsive = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 968);

    const handleResize = () => {
        setIsMobile(window.innerWidth < 968);
    };
    // Add event listener for window resize
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return { isMobile, setIsMobile };

}

export default useResponsive;