import React, { useContext, useState } from "react";
import { IconButton, Input, Tooltip, Stack, useToast } from "@chakra-ui/react";
import { AddIcon } from '@chakra-ui/icons';
import { ApiContext } from "../../ApiContext";
import SaveDiscardButtons from "./SaveDiscardButtons";

const AddJournalForm = ({
    onConfirm,
    onAfterConfirm = null,
    isEditingMode = false,
    initialValue = null,
}) => {
    const { loggedUserId } = useContext(ApiContext);
    const [editingMode, setEditingMode] = useState(isEditingMode);
    const [journalTitle, setJournalTitle] = useState(initialValue);
    const toast = useToast();

    const toggleEditingMode = () => setEditingMode((prev) => !prev);

    const handleCancel = () => {
        setEditingMode(false);
        onAfterConfirm && onAfterConfirm();
    }
    const handleSave = () => {
        setEditingMode(false);
        onConfirm.mutate({ title: journalTitle, userId: loggedUserId });
        toast({
            title: 'New journal created',
            // description: newJournal.title,
            status: 'success',
            duration: 1500,
            isClosable: true,
        });
        onAfterConfirm && onAfterConfirm();
    }

    return (
        <Stack direction={"row"}>
            {editingMode && (
                <Tooltip hasArrow label='This will be the name of the journal' openDelay={500}>
                    <Input
                        placeholder={initialValue}
                        onChange={(e) => setJournalTitle(e.target.value)}
                    />
                </Tooltip>
            )}
            {!editingMode && (
                <Tooltip hasArrow label='Create a new Journal'>
                    <IconButton
                        size='sm'
                        aria-label='Create Journal'
                        marginBottom={"2rem"}
                        onClick={toggleEditingMode}
                        bg={"teal"}
                        icon={<AddIcon />}
                    />
                </Tooltip>
            )}
            {editingMode && (
                <SaveDiscardButtons onSave={handleSave} onCancel={handleCancel} />
            )}
        </Stack>
    );
}

export default AddJournalForm;