import React from "react";
import { Menu, MenuButton, MenuList, MenuItem, MenuDivider } from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from '@chakra-ui/icons';
import OptionMenuIcon from "../icons/OptionMenuIcon";
import ConfirmationDialog from "./ConfirmationDialog";
import TaskMenu from "./TaskMenu";
import SaveFavouriteToggle from "./SaveFavouriteToggle";

const EditDeleteMenu = ({ document, onEdit, onConfirmDelete, icon = <OptionMenuIcon />, withTaskOptions = false }) => {
    return (
        <Menu>
            <MenuButton
                px={2}
                py={1}
                transition='all 0.2s'
                borderRadius='md'
                _hover={{ bg: 'gray.600' }}
                _expanded={{ bg: 'gray.600' }}
            // _focus={{ boxShadow: 'outline' }}
            >
                {icon}
            </MenuButton>
            <MenuList>
                <SaveFavouriteToggle document={document} isMenuItem />
                <MenuItem icon={<EditIcon />} onClick={onEdit}>Edit</MenuItem>
                {withTaskOptions && (<TaskMenu document={document} />)}
                <MenuDivider />
                <ConfirmationDialog modalTitle="Delete" onConfirm={onConfirmDelete}>
                    <MenuItem icon={<DeleteIcon />} color={"red.400"}>
                        Delete
                    </MenuItem>
                </ConfirmationDialog>
            </MenuList>
        </Menu>
    );
}

export default EditDeleteMenu;