import { Spinner, Stack } from "@chakra-ui/react";
import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useCreateJournal from "../queries/useCreateJournal";
import useJournals from "../queries/useJournals";
import AddJournalForm from "./controls/AddJournalForm";
import JournalFragment from "./JournalFragment";
import ResponsiveLayout from "./layout/ResponsiveLayout";
import Sidebar from "./layout/Sidebar";
import LogoIcon from "./icons/LogoIcon";


function Dashboard() {
    const { journalId } = useParams();
    const navigate = useNavigate();
    const { journals, isLoading } = useJournals();
    const getJournal = () => journals.results.filter((x) => x.id === journalId)[0];

    // Redirect to the first journal when visiting the homepage
    if (journals.count && !journalId) {
        navigate(`/journal/${journals[0].id}/`);
    }

    return (
        <ResponsiveLayout
            navbar={
                <LogoIcon width={"auto"} height={"1.5rem"} fillColor={"#fff"} compact />
            }
            sidebarContent={
                <Sidebar isLoading={isLoading}>
                    <Stack spacing={3}>
                        <AddJournalForm onConfirm={useCreateJournal()} />
                        {journals.count && !isLoading ? (
                            journals.results.map((journal) => (
                                <Link key={journal.id} to={`/journal/${journal.id}/`}>
                                    {journal.id === journalId
                                        ? <b>{journal.title}</b>
                                        : <span>{journal.title}</span>
                                    }
                                </Link>
                            ))
                        ) : (
                            <Spinner />
                        )}
                    </Stack>
                </Sidebar>
            }
            mainContent={
                !isLoading && journals.count && journalId
                    ? <JournalFragment isLoading={isLoading} journal={getJournal()}/>
                    : <Spinner />
            }
        // footer={
        //     <Center><LogoIcon width={"auto"} height={".75rem"} fillColor={"#fff"} marginRight={".2rem"} /> <Text fontSize={"sm"}>© 2023</Text></Center>
        // }
        />
    );
}

export default Dashboard;
