import { Icon } from '@chakra-ui/react';

const LogoIcon = ({width, height="auto", fillColor = "#ffffff", compact = false, ...rest}) => {

    return compact
        ? <Icon viewBox='0 0 560 133' width={width} height={height} {...rest}>
            <g transform="translate(0,130) scale(0.1,-0.1)" fill={fillColor} stroke="none">
                <path d="M134 1263 c3 -21 8 -68 12 -105 l6 -68 345 0 344 0 -5 48 c-3 26 -8 73 -11 105 l-6 57 -346 0 -345 0 6 -37z" />
                <path d="M338 914 c-81 -19 -134 -48 -198 -110 -92 -88 -130 -186 -130 -339 0 -153 42 -268 128 -346 175 -162 521 -155 695 15 166 161 166 498 -1 665 -88 88 -205 132 -352 130 -47 0 -111 -7 -142 -15z m198 -215 c95 -24 152 -131 142 -269 -10 -130 -80 -201 -201 -201 -107 0 -169 54 -187 167 -27 157 30 277 142 304 47 11 54 11 104 -1z" />
            </g>
        </Icon>
        : <Icon viewBox='0 0 560 133' width={width} height={height} {...rest}>
            <g transform="translate(0,133) scale(0.1,-0.1)" fill={fillColor} stroke="none">
                <path d="M954 1283 c3 -21 8 -68 12 -105 l6 -68 345 0 344 0 -5 48 c-3 26 -8 73 -11 105 l-6 57 -346 0 -345 0 6 -37z" />
                <path d="M2365 944 c-32 -8 -92 -32 -132 -54 l-73 -39 -32 39 c-31 40 -32 40 -100 40 l-68 0 0 -445 0 -445 135 0 135 0 0 319 0 318 60 27 c70 32 147 37 185 12 57 -37 60 -55 63 -379 l4 -297 135 0 135 0 -3 312 -3 313 65 33 c110 56 199 44 239 -34 12 -23 16 -86 18 -327 l3 -297 135 0 134 0 0 273 c-1 309 -9 396 -45 476 -47 101 -103 142 -225 162 -55 9 -86 9 -140 0 -72 -13 -195 -66 -219 -94 -17 -21 -37 -22 -44 -3 -9 24 -90 73 -147 90 -65 19 -135 19 -215 0z" />
                <path d="M221 934 c-136 -36 -210 -125 -211 -252 0 -134 54 -184 332 -305 66 -29 95 -65 79 -96 -23 -43 -126 -45 -248 -6 -46 14 -86 26 -90 26 -11 0 -82 -174 -76 -184 11 -18 110 -59 191 -78 231 -56 441 19 486 172 53 176 -29 286 -288 389 -104 42 -127 65 -97 101 17 21 27 24 88 23 47 -1 91 -10 141 -29 41 -14 75 -25 76 -23 2 2 19 43 40 92 28 70 34 91 24 98 -112 76 -313 108 -447 72z" />
                <path d="M1158 934 c-81 -19 -134 -48 -198 -110 -92 -88 -130 -186 -130 -339 0 -153 42 -268 128 -346 175 -162 521 -155 695 15 166 161 166 498 -1 665 -88 88 -205 132 -352 130 -47 0 -111 -7 -142 -15z m198 -215 c95 -24 152 -131 142 -269 -10 -130 -80 -201 -201 -201 -107 0 -169 54 -187 167 -27 157 30 277 142 304 47 11 54 11 104 -1z" />
                <path d="M4973 935 c-156 -42 -270 -153 -310 -302 -10 -35 -16 -97 -16 -158 1 -155 44 -259 143 -349 129 -116 382 -139 565 -51 174 83 259 257 235 477 -22 196 -140 334 -325 383 -73 19 -222 19 -292 0z m200 -216 c81 -22 132 -95 144 -209 12 -117 -40 -223 -124 -251 -50 -16 -135 -14 -171 5 -70 36 -108 133 -99 252 9 117 55 182 143 203 53 12 63 12 107 0z" />
                <path d="M3602 593 c3 -329 4 -340 27 -397 32 -81 101 -149 172 -170 121 -36 283 -10 403 65 l50 31 36 -41 c35 -41 36 -41 103 -41 l67 0 0 445 0 445 -135 0 -135 0 -2 -317 -3 -318 -60 -30 c-76 -38 -169 -42 -205 -10 -47 42 -50 65 -50 380 l0 295 -136 0 -135 0 3 -337z" />
            </g>
        </Icon>
}

export default LogoIcon;