import jwtDecode from "jwt-decode";

const JWT_TOKEN_KEY = 'jwtToken';

const setJwtToken = ({ value }) => localStorage.setItem(JWT_TOKEN_KEY, value);

const removeJwtToken = () => localStorage.removeItem(JWT_TOKEN_KEY);

const getJwtToken = () => localStorage.getItem(JWT_TOKEN_KEY);

const extractJwtTokenParts = () => {
    const jwtToken = localStorage.getItem(JWT_TOKEN_KEY);
    if (!jwtToken) {
        return {};
    }
    const decodedToken = jwtDecode(jwtToken);
    return {
        userId: decodedToken?.user_id,
        expirationTime: decodedToken?.exp,
        jwtToken,
    };
};

const isJwtTokenValid = () => {
    const { expirationTime, jwtToken } = extractJwtTokenParts();
    if (!jwtToken) {
        return false;
    }
    const currentTime = Date.now() / 1000;

    // Check if the token is expired
    if (expirationTime < currentTime) {
        // Token expired, perform logout or refresh token logic
        // You can redirect to the login page or request a new token
        // Example: localStorage.removeItem(JWT_TOKEN_KEY);
        removeJwtToken();
        return false;
    }

    return true;
};

export { isJwtTokenValid, extractJwtTokenParts, getJwtToken, setJwtToken, removeJwtToken };
