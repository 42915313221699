import React from "react";
import {
  Container, Flex, Spinner,
} from "@chakra-ui/react";
import JournalHeader from "./layout/JournalHeader";
import { Outlet } from "react-router-dom";
import usePageTitle from "../hooks/usePageTitle";

const JournalFragment = ({ journal, isLoading }) => {
  usePageTitle([journal.title, "Journal"]);
  return (
    <Flex minHeight={"calc(100vh - 56px)"}>
      <Container maxW='container.md'>
        <JournalHeader journal={journal} />
        {!isLoading ? <Outlet /> : <Spinner />}
      </Container>
      {/* <FragmentSelector /> */}
    </Flex>
  );
}

export default JournalFragment;
