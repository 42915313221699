import { useEffect } from "react";
import { PAGE_TITLE_SEPARATOR, SITE_TITLE, SITE_TITLE_SEPARATOR } from "../contants/SiteConstants";

const usePageTitle = (titleParts = []) => {
    const title = (
        titleParts.length > 0
            ? [titleParts.join(PAGE_TITLE_SEPARATOR), SITE_TITLE].join(SITE_TITLE_SEPARATOR)
            : SITE_TITLE
    );
    useEffect(() => {
        document.title = title;
    }, [title]);
}

export default usePageTitle;