import React from "react";
import { IconButton, Tooltip, Stack } from "@chakra-ui/react";
import { SmallCloseIcon, CheckIcon } from '@chakra-ui/icons';

const SaveDiscardButtons = ({ onSave, onCancel }) => {
    return (
        <Stack direction={"row"}>
            <Tooltip hasArrow label='Cancel'>
                <IconButton onClick={onCancel} icon={<SmallCloseIcon />} />
            </Tooltip>
            <Tooltip hasArrow label='Save'>
                <IconButton colorScheme="teal" onClick={onSave} icon={<CheckIcon />} />
            </Tooltip>
        </Stack>
    );
}

export default SaveDiscardButtons;