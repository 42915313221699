import { Flex } from '@chakra-ui/react';
import { ResponsiveBar } from '@nivo/bar'

const SomuoBarChart = ({ records, labelColumn, valueColumns }) => {
    const theme = {
        axis: {
            ticks: {
                line: {
                    stroke: 'rgba(255, 255, 255, 0.8)',
                },
                text: {
                    fill: 'rgba(255, 255, 255, 0.8)',
                }
            },
            legend: {
                text: {
                    fill: 'rgba(255, 255, 255, 0.8)',
                }
            },
        },
        grid: {
            line: {
                stroke: '#888',
                strokeWidth: 0.1,
            },
        },
        tooltip: {
            container: {
                background: "#171820",
                fontSize: 12
            },
        }
    };
    return (
        <Flex height={"400px"}>
            <ResponsiveBar
                theme={theme}
                data={records}
                keys={valueColumns}
                indexBy={labelColumn}
                margin={{ top: 50, right: 100, bottom: 100, left: 60 }}
                padding={0.3}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={{ scheme: 'nivo' }}
                // defs={[
                //     {
                //         id: 'dots',
                //         type: 'patternDots',
                //         background: 'inherit',
                //         color: '#38bcb2',
                //         size: 4,
                //         padding: 1,
                //         stagger: true
                //     },
                //     {
                //         id: 'lines',
                //         type: 'patternLines',
                //         background: 'inherit',
                //         color: '#eed312',
                //         rotation: -45,
                //         lineWidth: 6,
                //         spacing: 10
                //     }
                // ]}
                // fill={[
                //     {
                //         match: {
                //             id: 'fries'
                //         },
                //         id: 'dots'
                //     },
                //     {
                //         match: {
                //             id: 'sandwich'
                //         },
                //         id: 'lines'
                //     }
                // ]}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -45,
                    legend: labelColumn,
                    legendPosition: 'middle',
                    legendOffset: 32,
                }}
                // axisLeft={{
                //     tickSize: 5,
                //     tickPadding: 5,
                //     tickRotation: 0,
                //     legend: 'food',
                //     legendPosition: 'middle',
                //     legendOffset: -40,
                // }}
                // labelFormat=".0s"
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                }}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        itemTextColor: theme.axis.legend.text.fill,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1,
                                }
                            }
                        ]
                    }
                ]}
                // tooltip={({ id, value, color }) => (
                //     <div style={{ padding: 12, color, background: '#222222' }}>
                //         <span>Look, I'm custom</span>
                //         <br />
                //         <strong>
                //             {id}: {value}
                //         </strong>
                //     </div>
                // )}
                valueFormat=" >-.0s"
                role="application"
                ariaLabel="Nivo bar chart demo"
                barAriaLabel={e => e.id + ": " + e.formattedValue + " in country: " + e.indexValue}
            />
        </Flex>
    );
}

export default SomuoBarChart;
