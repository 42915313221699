import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Text,
    Badge,
    Flex,
} from '@chakra-ui/react'

const DatasetTable = ({ collection }) => {
    return (
        Object.keys(collection).length > 0 &&
        <Accordion allowMultiple marginTop="2rem">
            <AccordionItem>
                <AccordionButton>
                    <Flex alignItems="flex-end" gap="0.5rem" as="span" flex='1' textAlign='left' size="sm">
                        <Text fontSize="sm">Dataset</Text>
                        <Badge>
                            {Object.keys(collection).length}
                        </Badge>
                    </Flex>
                    <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                    <TableContainer>
                        <Table size='sm'>
                            <Thead>
                                <Tr>
                                    {Object.keys(collection[0]).map((key) => (
                                        <Th key={`th-${key}`}>{key}</Th>
                                    ))}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {collection.map((record) =>
                                    <Tr>{
                                        Object.keys(record).map((key) => (
                                            <Td key={`${record}-${key}`}>{record[key]}</Td>
                                        ))
                                    }</Tr>
                                )}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    );
}

export default DatasetTable;