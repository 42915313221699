import { Flex, Spinner } from "@chakra-ui/react";
import LogoIcon from "../icons/LogoIcon";

const FullScreenSpinner = () => {
    return (
        <Flex gap={"2rem"} direction={"column"} minHeight={"100vh"} justifyContent={"center"} alignItems={"center"}>
            <LogoIcon width={"10rem"} />
            <Spinner size={"xl"} />
        </Flex>
    );
}

export default FullScreenSpinner;