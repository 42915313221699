import { useContext } from "react";
import {
    useMutation,
    useQueryClient,
} from '@tanstack/react-query';
import { ApiContext } from "../ApiContext";

const useUpdateDailyLog = ({ journalId }) => {
    const { api } = useContext(ApiContext);
    const queryClient = useQueryClient();

    const mutation = useMutation({
        queryKey: ["journals"],
        mutationFn: async ({
            content, userId, dailyLogId, taskProgress = undefined, isStarred = undefined
        }) => api.put(`/dailylogs/${dailyLogId}/`, {
            content,
            author: userId,
            journal: journalId,
            ...(taskProgress !== undefined && { progress_status: taskProgress }),
            ...(isStarred !== undefined && { is_starred: isStarred }),
        }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['dailylogs', journalId] });
        },
    });
    return mutation;
}

export default useUpdateDailyLog;