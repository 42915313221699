import { Box, Text } from "@chakra-ui/react";

const EmptyPlaceholder = ({ message }) => {
    return (
        <Box textAlign={"center"}>
            <Text>
                {message}
            </Text>
            <Text>🚀</Text>
        </Box>
    );
}

export default EmptyPlaceholder;