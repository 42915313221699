import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const SomuoLineChart = ({ records, labelColumn, valueColumns }) => {
    const colorPalette = ["#ffcc00", "#ccee66", "#990066", "#006699", "#99cc33", "#cc3399", "#3399cc", "#669900", "#ff6600", "#ff9900"];

    return (
        <ResponsiveContainer minWidth={"500px"} minHeight={"300px"}>
            <LineChart
                data={records}
                margin={{
                    top: 0,
                    right: 5,
                    left: 0,
                    bottom: 0,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey={labelColumn}
                    axisLine={{ stroke: '#EAF0F4' }}
                    label={{ fill: '#EAF0F4' }}
                    tick={{ fill: '#EAF0F4' }}
                    tickLine={{ stroke: '#EAF0F4' }}
                />
                <YAxis
                    axisLine={{ stroke: '#EAF0F4' }}
                    label={{ fill: '#EAF0F4' }}
                    tick={{ fill: '#EAF0F4' }}
                    tickLine={{ stroke: '#EAF0F4' }}
                />
                <Tooltip
                    contentStyle={{ backgroundColor: 'rgba(25, 29, 39, 0.8)' }}
                />
                <Legend />
                {valueColumns.map(
                    (series, idx) => <Line type="monotone" dataKey={series} stroke={colorPalette[idx]} />
                )}
            </LineChart>
        </ResponsiveContainer>
    );
}

export default SomuoLineChart;
