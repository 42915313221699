import { AtSignIcon, ChatIcon, CheckCircleIcon, StarIcon } from "@chakra-ui/icons";
import { Button, Flex, Input, List, ListIcon, ListItem, Spinner, Text } from "@chakra-ui/react";
import { Suspense, useState } from "react";
import { useNavigate } from "react-router-dom";
import usePageTitle from "../hooks/usePageTitle";
import useCreateJournal from "../queries/useCreateJournal";
import useJournals from "../queries/useJournals";
import { extractJwtTokenParts } from "../utils/jwtTokenUtils";
import FullScreenSpinner from "./controls/FullScreenSpinner";
import LogoIcon from "./icons/LogoIcon";
import ResponsiveLayout from "./layout/ResponsiveLayout";

const SetupWizard = () => {
    const [journalTitle, setJournalTitle] = useState("");
    const createJournal = useCreateJournal();
    const { userId } = extractJwtTokenParts();
    const { journals, isLoading } = useJournals();
    const navigate = useNavigate();
    usePageTitle(["Setup"]);

    const handleClick = () => {
        createJournal.mutate({ title: journalTitle, userId: userId });
    }

    if (!isLoading && journals.length > 0) {
        navigate("/journal");
    }

    return (
        isLoading
            ?
            <FullScreenSpinner />
            : <Suspense fallback={<Spinner />}>
                <ResponsiveLayout
                    mainContent={
                        <Flex direction={"column"} gap={"1rem"}>
                            <LogoIcon width={"15rem"} height={"auto"} marginBottom={"3rem"} />
                            <Text fontSize={"2rem"}>Let's start!</Text>
                            <Text>We're going to setup the first <b>📔 Journal</b> together.</Text>
                            <Text>You can think of A <b>Journal</b> as your personal feed.
                                You can add there almost anything you can think of:</Text>
                            <List spacing={3} marginLeft={"3rem"}>
                                <ListItem>
                                    <ListIcon as={ChatIcon} color='teal.600' />
                                    Write your notes
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={CheckCircleIcon} color='teal.400' />
                                    Track the progress of your tasks
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={StarIcon} color='teal.600' />
                                    Create a dashboard with charts
                                </ListItem>
                                <ListItem>
                                    <ListIcon as={AtSignIcon} color='teal.400' />
                                    Collaborate with your team in a shared environment
                                </ListItem>
                            </List>
                            <Text fontSize={"2rem"} marginTop={"2rem"}>Are you ready !?</Text>
                            <Text>Type the name of your first <b>Journal</b>:</Text>
                            <Input
                                fontSize={"2rem"}
                                paddingTop={"1.8rem"}
                                paddingBottom={"1.8rem"}
                                placeholder='🚀 My Amazing Journal' maxWidth={"30ch"}
                                variant='flushed'
                                onChange={(e) => {
                                    setJournalTitle(e.target.value);
                                }}
                            />
                            {journalTitle.length > 2 &&
                                <Button
                                    bg={"teal.400"}
                                    variant={"solid"}
                                    maxWidth={"30ch"}
                                    onClick={handleClick}
                                >
                                    Create
                                </Button>
                            }
                        </Flex>
                    }
                />
            </Suspense>
    );
}

export default SetupWizard;