import React from "react";
import { Flex, Spacer, Box, Skeleton, Text } from "@chakra-ui/react";
import LogoIcon from "../icons/LogoIcon";

const Sidebar = ({ children, isLoading }) => {

    return (
        <Flex flexDirection={"column"} minHeight={"100vh"} padding={"1.2rem"}>
            {!isLoading
                ? (
                    <Box>
                        {children}
                    </Box>
                )
                : (
                    <Box>
                        <Skeleton width={"3rem"} height={"3rem"} marginBottom={"1rem"} />
                        <Skeleton height={"4"} marginBottom={"1rem"} />
                        <Skeleton height={"4"} marginBottom={"1rem"} />
                        <Skeleton height={"4"} marginBottom={"1rem"} />
                        <Skeleton height={"4"} marginBottom={"1rem"} />
                        <Skeleton height={"4"} marginBottom={"1rem"} />
                        <Skeleton height={"4"} marginBottom={"1rem"} />
                    </Box>
                )
            }
            <Spacer />

            <Flex alignItems={"center"}>
                <LogoIcon width={"auto"} height={".75rem"} fillColor={"rgba(255, 255, 255, 0.5)"} marginRight={".2rem"} />
                <Text fontSize={"sm"} color={"rgba(255, 255, 255, 0.5)"}>© 2023</Text>
            </Flex>
        </Flex>
    );

}

export default Sidebar;