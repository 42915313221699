import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import { IconButton, MenuItem } from "@chakra-ui/react";
import { StarIcon } from '@chakra-ui/icons';
import useUpdateDailyLog from "../../queries/useUpdateDailyLog";
import { extractJwtTokenParts } from "../../utils/jwtTokenUtils";

const SaveFavouriteToggle = ({ document, isMenuItem = false }) => {
    const { journalId } = useParams();
    const { userId } = extractJwtTokenParts();
    const updateDailyLog = useUpdateDailyLog({ journalId });

    const toggleStarred = useCallback(() => {
        const documentUpdatePayload = { dailyLogId: document?.id, userId, ...document };
        updateDailyLog.mutate({
            isStarred: !document.is_starred,
            ...documentUpdatePayload
        });
    }, [document, updateDailyLog, userId]);

    return (
        isMenuItem
            ? <MenuItem icon={<StarIcon color={document?.is_starred && "yellow.600"} />}
                onClick={toggleStarred}>
                {document?.is_starred ? "Remove from Favourites" : "Save as Favourite"}
            </MenuItem>
            :
            <IconButton
                variant={"ghost"}
                icon={<StarIcon color={document?.is_starred && "yellow.600"} />}
                onClick={toggleStarred}
            />
    );
}

export default SaveFavouriteToggle;