import React from "react";
import RichTextEditorQuill from "../controls/RichTextEditorQuill";
import Moment from "react-moment";
import {
    Badge,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Flex,
    Spacer,
    Text,
} from "@chakra-ui/react";
import { TASK_COLORS } from "../../contants/TaskConstants";
import SaveFavouriteToggle from "../controls/SaveFavouriteToggle";
import TooltipAvatar from "../controls/TooltipAvatar";
import MetadataTable from "./MetadataTable";

const DailyLogCard = ({ document }) => {
    return (
        <Card>
            {document.is_task && (
                <CardHeader paddingBottom={0}>
                    <Flex gap={2} alignItems={"flex-end"}>
                        <Text fontSize='sm'>
                            Task:
                        </Text>
                        <Badge colorScheme={TASK_COLORS[document.progress_status]}>
                            {document.progress_status}
                        </Badge>
                    </Flex>
                </CardHeader>
            )}
            <CardBody paddingTop="2rem">
                <RichTextEditorQuill
                    initialContent={document.content}
                    readOnly={true}
                    document={document}
                />
                {<MetadataTable collection={document.metadata} />}
            </CardBody>
            <CardFooter marginTop={0} paddingTop={0}>
                <Flex minWidth="max-content" flex='1' gap='4' justifyContent={"flex-start"} alignItems='center' flexWrap='wrap'>
                    <TooltipAvatar size="sm" name={document.author_username} />
                    <Text fontSize='sm'>
                        <Moment format="DD/MM • HH:mm A">
                            {document.updated_at}
                        </Moment>
                    </Text>
                    <Spacer />
                    <SaveFavouriteToggle document={document} />
                </Flex>
            </CardFooter>
        </Card>
    );
};

export default DailyLogCard;
