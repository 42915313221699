import { useContext } from "react";
import {
    useMutation,
    useQueryClient,
} from '@tanstack/react-query';
import { ApiContext } from "../ApiContext";

const useCreateDailyLog = ({ journalId }) => {
    const { api } = useContext(ApiContext);
    const queryClient = useQueryClient();

    const mutation = useMutation({
        queryKey: ["journals"],
        mutationFn: async ({ content, userId }) => api.post("/dailylogs/", {
            content,
            author: userId,
            journal: journalId,
        }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['dailylogs', journalId] });
        },
    });
    return mutation;
}

export default useCreateDailyLog;