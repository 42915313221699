import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { extractJwtTokenParts } from "../../utils/jwtTokenUtils";
import { Box } from "@chakra-ui/react";
import SaveDiscardButtons from "./SaveDiscardButtons";
import useCreateDailyLog from "../../queries/useCreateDailyLog";
import useUpdateDailyLog from "../../queries/useUpdateDailyLog";
import useDeleteDailyLog from "../../queries/useDeleteDailyLog";
import EditDeleteMenu from "./EditDeleteMenu";
import ReactQuill from 'react-quill';
import "react-quill/dist/quill.bubble.css";
import "react-quill/dist/quill.snow.css";
import './quill.custom.css';

const RichTextEditorQuill = ({ initialContent, readOnly, document, alwaysEditable = false }) => {
    const [content, setContent] = useState(initialContent);
    const { journalId } = useParams();
    const [isReadOnly, setReadOnly] = useState(readOnly);
    const { userId } = extractJwtTokenParts();

    const createDailyLog = useCreateDailyLog({ journalId });
    const updateDailyLog = useUpdateDailyLog({ journalId });
    const deleteDailyLog = useDeleteDailyLog({ journalId, dailyLogId: document?.id });

    const handleSave = () => {
        document
            ? updateDailyLog.mutate({
                content, userId, dailyLogId: document.id, isStarred: document.is_starred
            })
            : createDailyLog.mutate({ content, userId });
        alwaysEditable ? setContent(initialContent) : setReadOnly(true);
    };

    const handleCancel = () => {
        setContent(initialContent);
        if (!alwaysEditable) {
            setReadOnly(true);
        }
    };

    const handleEdit = () => setReadOnly(false);

    const isDailyLogEntry = () => !isReadOnly && !alwaysEditable;

    const isJournalRootEditorWithContent = () => {
        if (!alwaysEditable)
            return false;
        const emptyContent = "<p><br></p>";
        return content.length > 0 && content !== emptyContent;
    }

    const classes = [
        "text-editor",
        alwaysEditable ? "root-editor" : "post-editor",
        !isJournalRootEditorWithContent() ? "empty-editor" : ""
    ];

    return (
        <Box>
            <ReactQuill
                className={classes.join(" ")}
                theme={alwaysEditable ? "snow" : "bubble"}
                value={content}
                onChange={setContent}
                readOnly={isReadOnly}
                placeholder={"Type..."}
                modules={{
                    clipboard: {
                        matchVisual: false
                    },
                    toolbar: [
                        [{ 'header': [1, 2, false] }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [
                            { 'list': 'ordered' },
                            { 'list': 'bullet' },
                            // { 'indent': '-1' },
                            // { 'indent': '+1' },
                        ],
                        ['link', 'image'],
                        ['clean']
                    ],
                }}
            />

            {document && isReadOnly && (
                // option menu for the daily log
                <Box sx={{ position: "absolute", top: "0.5rem", right: "0.5rem" }}>
                    <EditDeleteMenu
                        document={document}
                        onConfirmDelete={deleteDailyLog.mutate}
                        onEdit={handleEdit}
                        withTaskOptions
                    />
                </Box>

            )}
            {isDailyLogEntry() && (
                <SaveDiscardButtons onSave={handleSave} onCancel={handleCancel} />
            )}
            {isJournalRootEditorWithContent() && (
                <SaveDiscardButtons onSave={handleSave} onCancel={handleCancel} />
            )}
        </Box>
    );
};

export default RichTextEditorQuill;
