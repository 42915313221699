import { SettingsIcon } from "@chakra-ui/icons";
import { Flex, Heading, Spacer } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useDeleteJournal from "../../queries/useDeleteJournal";
import useUpdateJournal from "../../queries/useUpdateJournal";
import AddJournalForm from "../controls/AddJournalForm";
import ContributorAvatars from "../controls/ContributorAvatars";
import EditDeleteMenu from "../controls/EditDeleteMenu";

const JournalHeader = ({ journal }) => {
    const navigate = useNavigate();
    const [isUpdating, setUpdating] = useState(false);
    const deleteJournal = useDeleteJournal({ journalId: journal.id });
    const updateJournal = useUpdateJournal({ journalId: journal.id });
    const journalTitle = journal.title;

    const handleDelete = () => {
        deleteJournal.mutate();
        navigate("/");
    };
    const handleUpdate = () => setUpdating(true);


    return (
        <Flex gap={3} alignItems={"flex-end"} justifyContent={"flex-start"}>
            <Heading as="h2" size="lg">
                {!isUpdating
                    ? journalTitle :
                    <AddJournalForm
                        onConfirm={updateJournal}
                        initialValue={journalTitle}
                        isEditingMode={true}
                        onAfterConfirm={() => setUpdating(false)}
                    />
                }
            </Heading>
            {/* <UrlClipboard content={{journalId: journal.id}} /> */}
            <Spacer />
            <ContributorAvatars journal={journal} users={journal.contributors_usernames ?? []} />
            <EditDeleteMenu
                onConfirmDelete={handleDelete}
                onEdit={handleUpdate}
                icon={<SettingsIcon />}
            />
        </Flex>
    );
}

export default JournalHeader;