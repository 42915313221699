import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import { Badge, MenuItem, MenuDivider, MenuGroup } from "@chakra-ui/react";
import { PlusSquareIcon } from '@chakra-ui/icons';
import useUpdateDailyLog from "../../queries/useUpdateDailyLog";
import { extractJwtTokenParts } from "../../utils/jwtTokenUtils";
import { NEW, TASK_COLORS, TASK_STATUSES } from "../../contants/TaskConstants";

const TaskMenu = ({ document }) => {
    const { journalId } = useParams();
    const { userId } = extractJwtTokenParts();
    const updateDailyLog = useUpdateDailyLog({ journalId });

    const markTaskStatus = useCallback((taskStatus) => {
        const documentUpdatePayload = { dailyLogId: document.id, userId, ...document }
        updateDailyLog.mutate({
            taskProgress: taskStatus,
            isStarred: document.is_starred,
            ...documentUpdatePayload
        });
    }, [document, updateDailyLog, userId]);

    const unsetTask = () => markTaskStatus("");

    return (
        !document.is_task
            ? <MenuItem icon={<PlusSquareIcon />}
                onClick={() => markTaskStatus(NEW)}>Convert to Task
            </MenuItem>
            : <>
                <MenuDivider />
                <MenuGroup title='Task'>

                    {TASK_STATUSES
                        .filter((status) => status !== document.progress_status)
                        .map((status) => <MenuItem
                            key={`set-${document.id}-task-${status}`}
                            onClick={() => markTaskStatus(status)}>
                            Mark as <Badge colorScheme={TASK_COLORS[status]} marginLeft={"0.5rem"}>{status}</Badge>
                        </MenuItem>
                        )}
                    <MenuItem onClick={unsetTask}>Cancel Task</MenuItem>
                </MenuGroup>
            </>

    );
}

export default TaskMenu;