import { useContext } from "react";
import {
    useMutation,
    useQueryClient,
} from '@tanstack/react-query';
import { ApiContext } from "../ApiContext";

const useUpdateJournal = ({ journalId }) => {
    const { api } = useContext(ApiContext);
    const queryClient = useQueryClient();

    const mutation = useMutation({
        queryKey: ["journal"],
        mutationFn: async ({ title, userId, isStarred = undefined }) => api.put(`/journals/${journalId}/`, {
            title,
            author: userId,
            ...(isStarred !== undefined && { is_starred: isStarred }),
        }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['journals'] });
        },
    });
    return mutation;
}

export default useUpdateJournal;