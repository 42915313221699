import React from "react";
import Moment from "react-moment";
import {
    Card,
    CardBody,
    CardFooter,
    Flex,
    Spacer,
    Text,
} from "@chakra-ui/react";
import DatasetTable from "./DatasetTable";
import TooltipAvatar from "../controls/TooltipAvatar";
import SaveFavouriteToggle from "../controls/SaveFavouriteToggle";

const ChartCard = ({ children, document }) => {
    return (
        <Card>
            <CardBody>
                {children}
                <DatasetTable collection={document.records} />
            </CardBody>
            <CardFooter marginTop={0} paddingTop={0}>
                <Flex minWidth="max-content" flex='1' gap='4' justifyContent={"flex-start"} alignItems='center' flexWrap='wrap'>
                    <TooltipAvatar size="sm" name={document.author_username} />
                    <Text fontSize='sm'>
                        <Moment format="DD/MM • HH:mm A">
                            {document.updated_at}
                        </Moment>
                    </Text>
                    <Spacer />
                    <SaveFavouriteToggle document={document} />
                </Flex>
            </CardFooter>
        </Card>
    );
};

export default ChartCard;
